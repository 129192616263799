'use client';

import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { PersistedClient, Persister } from '@tanstack/react-query-persist-client';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import * as localForage from 'localforage';
import { useMemo } from 'react';

export const ReactQueryClientProvider = ({
	children,
	cacheKey,
	buster,
	gcTime = Infinity,
}: {
	children: React.ReactNode;
	cacheKey?: string;
	buster?: string;
	gcTime?: number;
}) => {
	const client = useMemo(() => createQueryClient(gcTime), [gcTime]);

	return (
		<PersistQueryClientProvider
			client={client}
			persistOptions={{
				persister: createIDBPersister(cacheKey),
				buster,
				maxAge: gcTime,
			}}
		>
			{children}
			<ReactQueryDevtools position="bottom-right" />
		</PersistQueryClientProvider>
	);
};

const createQueryClient = (gcTime = Infinity) => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				retry: 2,
				cacheTime: gcTime,
				staleTime: 1000 * 5,
			},
		},
	});
	return queryClient;
};

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey: IDBValidKey = 'vyg') {
	return {
		persistClient: async (client: PersistedClient) => {
			await localForage.setItem(idbValidKey.toString(), client);
		},
		restoreClient: async () => {
			return await localForage.getItem<PersistedClient>(idbValidKey.toString());
		},
		removeClient: async () => {
			await localForage.removeItem(idbValidKey.toString());
		},
	} as Persister;
}
